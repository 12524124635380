export const environment = {
  production: true,
  envName: 'staging',
  app_domain: 'http://staging.squeeze.com',
  offersApiUrl: 'https://squeeze-data-staging.herokuapp.com/api/v1/admin',
  internal_api_url: 'https://squeeze-internal-staging.herokuapp.com/api/v1',
  raters_api_url: 'https://squeeze-raters-staging.herokuapp.com/api/v1',
  partners_url: 'https://partners-staging.squeeze.com/',
  ezlynx_url: 'https://app.uatezlynx.com/',
};
